/**
 * FORMS
 */
@import "colors";
@import "text";
@import 'spacing';

.fixedToBottomOfScreen {
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100vw;
  line-height: 0;
}

.subText {
  font-size: $font-size-regular;
  font-family: $font-family-book;
  color: $color-white;
}

.formField,
.formFieldFile {
  padding-bottom: $margin-small;
}

.formField label {
  @extend .subText;
  display: block;
  color: $color-lana-del-grey;
  font-size: $font-size-medium;
  padding-bottom: $margin-xsmall;
}

.formField label.formCheckboxLabel {
  display: inline;
}

.formFieldError {
  font-size: $font-size-small;
  font-family: $font-family-medium;
  color: $color-lady-in-red;
  padding: $margin-xxsmall 0 0;
}

.formElement {
  border-radius: $standard-border-radius;
  padding: 8px;
  border: 0;
  background-color: $color-white;
  color: $color-fade-to-black;
}

.formButton {
  @extend .formElement;
  text-align: center;
  font-family: $font-family-bold;
  font-size: $font-size-large;
  cursor: pointer;
  margin-top: 10px;
  margin-bottom: 20px;
}

.pinkFormButton {
  @extend .formButton;
  color: $color-white;
  width: 320px;
  border-radius: $xxlarge-border-radius;
  margin: 5px;
  font-size: $font-size-4xlarge;
  background-color: $color-cherry-pie;
}

.grayFormButton {
  @extend .pinkFormButton;
  background: none;
  background-color: $color-lana-del-grey;
}

.grayButton {
  @extend .formButton;
  background-color: $color-grey-worm;
  border-radius: 0;
  color: $color-white;
  padding: 40px 0;
}

.grayButton:hover {
  background-color: $color-lana-del-grey;
}

.grayButton:active {
  background-color: $color-father-john-misty;
}

.greenButton {
  @extend .formButton;
  @extend .fixedToBottomOfScreen;
  background-color: $color-the-green-album;
  border-radius: 0;
  color: $color-white;
  padding: 40px 0;
}

.greenButton:hover {
  background-color: $color-granny-smith;
}

.greenButton:active {
  background-color: $color-minty-fresh;
}

.formInputTextHint {
  font-size: $font-size-large;
  font-family: $font-family-book;
  color: $color-dorian-grey;
}

.formFieldFile {
  padding: 0;
}

.formFieldFile p {
  color: $color-lana-del-grey;
  font-size: $font-size-regular;
  text-align: center;
  margin: $margin-small 0 $margin-large;
}

.geosuggest__suggests-wrapper {
  position: relative;
  z-index: 2;
}

.geosuggest__suggests--hidden {
  display: none;
}

.geosuggest__suggests {
  height: 0;
  overflow: visible;
  margin: 0;
  padding: 0;
}

.geosuggest__suggests li {
  background: #fff;
  padding: 8px 24px;
  border-top: 1px solid #ccc;
}

.geosuggest__suggests li.geosuggest-item--active {
  background: #ccc;
}

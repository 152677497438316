@import './src/shared';

.root {
  margin-top: 0;
  background: $color-the-white-album;
  color: $color-fade-to-black;
  font-family: $font-family-book;
  min-height: 100vh;
  min-width: 100vw;
  padding-top: 0;
}
